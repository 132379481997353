import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaMapMarkerAlt, FaMicrosoft, FaBed, FaCarAlt, FaHome, FaCheckCircle, FaBuilding, FaThumbsUp, FaBookmark, FaBirthdayCake } from "react-icons/fa";
import { useParams, useHistory } from 'react-router-dom';
import Background from './Background';
import { useState, useEffect } from 'react';
import './TableStyle.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

function UserDetails() {

    const { id } = useParams();
    const [cookies, setCookie] = useCookies(['user']);
    const [userDetails,setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getUserById = () => {
        var data = new FormData();
        data.append("id",id);
        setIsLoading(true);
        fetch('https://api.connecker.com/api/get_user_by_id', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(async(data) => {
            setIsLoading(false);
            if(data.response_code == 200) {
                setUserDetails(data.data);
               console.log(userDetails);
            } else if(data.response_code == 404) {
                setUserDetails({});
            }
         });
    }

    useEffect(() => {
        const abortController = new AbortController();
        getUserById();
    },[]);

    return(
        <div className="content">
            <div className="row justify-content-start">
                <div className="col-sm-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-sm-10">
                        <Background></Background>
                        <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>User Profile</h4>
                        <div className="row justify-content-start">
                            <div className="col-sm-6">
                                <div class="card" style={{width:"100%"}}>
                                    <div class="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                { userDetails != null ? <img src={`${userDetails.profile_pic}`} className="rounded-circle" style={{width:"150px", height:"150px", padding:"20px"}}/> : <div></div> }
                                            </div>
                                            <div className="col-sm-8">
                                                <h5 className="subHeading">{ userDetails != null ? ( userDetails.middle_name != null ? userDetails.first_name + " " + userDetails.middle_name + " " + userDetails.last_name : userDetails.first_name + " " + userDetails.last_name ) : "" }</h5>
                                                <br></br>
                                                <h6 className="subHeading">{ userDetails != null ? userDetails.email : "" }</h6>
                                                <h6 className="subHeading">{ userDetails != null ? userDetails.mobile : "" }</h6>
                                                <br></br>
                                                <p>{ userDetails != null ? ( userDetails.address != null ? userDetails.address : "" ) : "" }</p>
                                                <div className="d-flex paragraph">
                                                    { userDetails != null ? <FaBirthdayCake style={{ marginTop:"3px" }}></FaBirthdayCake> : <div></div>}
                                                    &nbsp;&nbsp; 
                                                    { userDetails != null ? userDetails.dob : "" }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div class="card" style={{width:"100%"}}>
                                    <div class="card-body">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col">
                                                    <center>
                                                        <FaBuilding style={{ width:"4rem",height:"4rem" }}></FaBuilding>
                                                        <br></br><br></br>
                                                        <h6 className="paragraph">{ userDetails != null ? userDetails.number_of_properties : "0" }</h6>
                                                        <h6 className="paragraph">Properties</h6>
                                                    </center>
                                                </div>
                                                <div class="col">
                                                    <center>
                                                        <FaThumbsUp style={{ width:"4rem",height:"4rem" }}></FaThumbsUp>
                                                        <br></br><br></br>
                                                        <h6 className="paragraph">{ userDetails != null ? userDetails.number_of_likes : "0" }</h6>
                                                        <h6 className="paragraph">Likes</h6>
                                                    </center>
                                                </div>
                                            </div>
                                            <div class="row" style={{ marginTop:"20px" }}>
                                                <div class="col">
                                                    <center>
                                                        <FaBookmark style={{ width:"4rem",height:"4rem" }}></FaBookmark>
                                                        <br></br><br></br>
                                                        <h6 className="paragraph">{ userDetails != null ? userDetails.number_of_bookmarks : "0" }</h6>
                                                        <h6 className="paragraph">Bookmarks</h6>
                                                    </center>
                                                </div>
                                                <div class="col"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default UserDetails;