import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaSearch } from "react-icons/fa";
import Background from './Background';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function Services() {

    const [cookies, setCookie] = useCookies(['user']);
    const [chatList,setChatList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const chartData = 0;

    // const getChatList = () => {
    //     var data = new FormData();
        
    //     data.append("user_id",11);
    //     setIsLoading(true);
    //     fetch('https://api.connecker.com/api/get_chats', {
    //         method: 'POST',
    //         body: data
    //     }).then(res => {
    //         setIsLoading(false);
    //         if(!res.ok) {
    //             throw Error('Could not fetch');
    //         }
    //         return res.json();
    //      }).then(data => {
    //         setIsLoading(false);
    //         if(data.response_code == 200) {
    //            setChatList(data.data);
    //         } else if(data.response_code == 404) {
    //             setChatList([]);
    //         }
    //      });
    // }

    // useEffect(() => {
    //     const abortController = new AbortController();
    //     getChatList();
    //    },[]);

    return(
        <div className="content">
            <div className="row justify-content-start">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                    <Background></Background>
                    <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>Services</h4>
                    
                    <div className="row">
                     <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/stamp.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Notaries</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_233.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Construction Companies</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_232.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Architects</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div> 
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_232.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Real Estate Agencies</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_236.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Real Estate Promoters</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_235.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Sub Contractors</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_232.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Surveyor</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_234.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Banks</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_237.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Real Estate Experts</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-sm-6 mb-3">
                            <div class="card" style={{width:"11rem", height:"11rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <center>
                                        <img src="/images/Mask_Group_238.svg" style={{width:"100px", height:"auto"}}></img>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 class="card-title subHeading" style={{ marginTop:"10px" }}>Interior Decorators</h5>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;