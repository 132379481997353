import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaMapMarkerAlt, FaMicrosoft, FaBed, FaCarAlt, FaHome, FaCheckCircle } from "react-icons/fa";
import { useParams, useHistory } from 'react-router-dom';
import Background from './Background';
import { useState, useEffect } from 'react';
import './TableStyle.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import { ToastContainer, toast } from 'react-toastify';



function PropertyDetails() {

    const { id } = useParams();
    //const [cookies, setCookie] = useCookies(['user']);
    const [property,setProperty] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [message,setMessage] = useState(null);
    const [lat,setLat] = useState(0);
    const [lon,setLon] = useState(0);

    const getPropertyById = () => {
        var data = new FormData();
        data.append("property_id",id);
        setIsLoading(true);
        fetch('https://api.connecker.com/api/get_property_by_id', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(async(data) => {
            setIsLoading(false);
            if(data.response_code == 200) {
                setProperty(data.data[0]);
                console.log('https://nominatim.openstreetmap.org/search?q='+data.data[0].address.replace(/ /g, "+")+'&format=json');
                fetch('https://nominatim.openstreetmap.org/search?q='+data.data[0].address.replace(/ /g, "+")+'&format=json',{
                method:'GET'
                }).then(res => {
                    if(!res.ok) {
                        throw Error('Could not fetch');
                    }
                    return res.json();
                }).then(data => {
                    console.log(data);
                    if(data != null) {
                        setLat(data[0].lat);
                        setLon(data[0].lon);
                        console.log(lat);
                        console.log(lon);
                    }
                });
               console.log(property);
            } else if(data.response_code == 404) {
                setProperty({});
            }
         });
    }

       const sendMessage = () => {
        var data = new FormData();
	if(property.user_id == 11) {
		toast("This property was created by Connecker");
		return;
	}
	if(message == null) {
		toast("Message cannot be empty");
		return;
	}
        data.append("sender_id",11);
	data.append("receiver_id",property.user_id);
        data.append("property_id",property.id);
	data.append("message",message);
	setIsLoading(true);
        fetch('https://api.connecker.com/api/send_message', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(async(data) => {
            setIsLoading(false);
            if(data.response_code == 200) {
            	toast("Message sent");
		setMessage(null);
            } else if(data.response_code == 404) {
                setProperty({});
            }
         });
    }



    const geocoding = () => {
        if(property != null) {
            fetch('https://nominatim.openstreetmap.org/search?q='+property.address+'&format=json',{
                method:'GET'
            }).then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch');
                }
                return res.json();
            }).then(data => {
                console.log(data);
            });
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        getPropertyById();
       },[]);

    return(
        <div className="content" style={{ paddingLeft:"40px" }}>
            <div className="row justify-content-start">
                {/*<div className="col-sm-2">
                    <Sidebar></Sidebar>
                </div>*/}
                <div className="col-sm-10">
                    {/*<Background></Background>*/}
                    <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>Property Details</h4>
                    <div className="row justify-content-start">
                        <div className="col-sm-8">
                            <div class="card" style={{width:"100%"}}>
                                <div class="card-body">
                                <Carousel>
                                    {   
                                        property != null ?
                                        property.pictures.map((picture, i) => (
                                            <Carousel.Item>
                                                <img
                                                className="d-block w-100"
                                                src={`${picture}`}
                                                alt=""
                                                />
                                            </Carousel.Item>
                                        ))
                                        :
                                        <div></div>
                                    }
                                </Carousel>
                                    
                                    {/*<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                        <div class="carousel-inner">
                                                {   property != null ?
                                                    property.pictures.map((picture, i) => (
                                                        (
                                                            i == 0 ?
                                                            <div class="carousel-item active">
                                                                <img src={`${picture}`} class="d-block w-100" alt="..."></img>
                                                            </div>
                                                            :
                                                            <div class="carousel-item">
                                                                <img src={`${picture}`} class="d-block w-100" alt="..."></img>
                                                            </div>
                                                        )
                                                    ))
                                                    :
                                                    <div></div>
                                                }
                                            
                                        </div>
                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>*/}
                                        {/* property != null ? ((property.pictures.length == 0) ? <img src="https://api.connecker.com/images/default_property_image.png" className="img-fluid"></img> : <img src={`${property.pictures[0]}`} className="img-fluid"></img>) : "" */}
                                    <h6 className="subHeading" style={{ color:"orange",paddingTop:"20px" }}><b>FCFA(XOF)&nbsp;{ property != null ? property.price : "" }</b></h6>
                                    <p className="paragraph" >{ property != null ? property.description : "" }</p>
                                    <div className="d-flex paragraph"><FaMapMarkerAlt></FaMapMarkerAlt>&nbsp; { property != null ? property.address : "" }</div>
                                    <br></br>
                                    <div className="d-flex paragraph" style={{ color:"orange" }}>
                                        <FaMicrosoft style={{ marginTop:"4px",marginRight:"5px" }}></FaMicrosoft>236
                                        <FaBed style={{ marginTop:"4px",marginRight:"5px", marginLeft:"20px" }}></FaBed>{ property != null ? property.beds : "" }
                                        <FaCarAlt style={{ marginTop:"4px",marginRight:"5px", marginLeft:"20px" }}></FaCarAlt>{ property != null ? property.beds : "" }
                                        <FaHome style={{ marginTop:"4px",marginRight:"5px", marginLeft:"20px" }}></FaHome>{ property != null ? property.beds : "" }
                                    </div>
                                    <br></br>
                                    <div className="d-flex subHeading"><h6 style={{ color:"orange" }}>General</h6>&nbsp;<h6>Amenities</h6></div>
                                    <br></br>
                                    <div class="container paragraph">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.bedroom == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Bedroom</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.bathroom == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Bathroom</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.livingroom == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Living Room</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.kitchen == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Kitchen</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.parking_area == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Parking Area</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.front_yard == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Front Yard</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.back_yard == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Back Yard</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.balcony == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Balcony</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.gym == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Gym</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.lift == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Lift</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.floor == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Floor</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.sofa == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Sofa</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.kingsizedbed == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;King Sized Bed</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.queensizedbed == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Queen Sized Bed</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.mattress == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Mattress</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.convertiblesofa == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Convertible Sofa</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.wardrobe == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Wardrobe</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.console == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Console</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.mirror == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Mirror</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.chair == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Chair</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.furniture_table == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Table</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.tv == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;TV</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.cable == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Cable</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.radio == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Radio</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.alarmclock == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Alarm Clock</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.wifi == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Wifi</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.fridge == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Fridge</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.oven == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Oven</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.microwave == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Microwave</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.airconditioner == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Air Conditioner</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.ventilatorfan == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Ventilator Fan</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.dryer == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Dryer</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.hairdryer == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Hair Dryer</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.dishwasher == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Dish Washer</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.washinmachine == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Washing Machine</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.kettle == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Kettle</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.coffeemachine == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Coffee Machine</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.teapot == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Teapot</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.waterheater == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Water Heater</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.cooker == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Cooker</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.generator == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Generator</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.watertank == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Water Tank</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.keeper == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Keeper</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.maid == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Maid</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.electricity == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Electricity</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.tv == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Bedsheets</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.cable == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Bathtowels</div>
                                            </div>
                                            <div class="col">

                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="d-flex paragraph"><h6 style={{ color:"orange" }}>Preferred</h6>&nbsp;<h6>Tenants</h6></div>
                                    <br></br>
                                    <div class="container paragraph">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.family == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Family</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.bachelor == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Bachelor</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.company == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Company</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex" style={{ border:"1px solid grey",borderRadius:"10px",width:"10rem",padding:"10px" }}><FaCheckCircle style={{ marginTop:"5px", color:(property != null ? (property.does_not_matter == 1 ? "green" : "grey") : "grey") }}></FaCheckCircle>&nbsp;&nbsp;Does Not Matter</div>
                                            </div>
                                            <div class="col">
                                                
                                            </div>
                                            <div class="col">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="d-flex subHeading"><h6 style={{ color:"orange" }}>Location</h6></div>
                                    <br></br>
                                    <img src={"https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/" + lon + "," +  lat + ",10,0,0/1280x1280?access_token=pk.eyJ1IjoiYXl1c2hyYWoxMDI0IiwiYSI6ImNreGllZzJzMzMxaWQydW8xaHY4bzlrbzkifQ.2Z9_i5FwUzK_uVWzZ0tdQw"} className="img-fluid"></img>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="card" style={{width:"100%"}}>
                                <div class="card-body">
                                    <center>
                                        { property != null ? ((property.user_profile_pic == null) ? <img src="https://api.connecker.com/images/default_profile_image.png" className="rounded-circle" style={{width:"150px", height:"150px", padding:"20px"}} ></img> : <img src={`${property.user_profile_pic}`} className="rounded-circle" style={{width:"150px", height:"150px", padding:"20px"}} ></img>) : ""}
                                        { property != null ? ((property.user_middle_name != null && property.user_middle_name != "null") ? <h6 className="paragraph">{property.user_first_name + " " + property.user_middle_name + " " + property.user_last_name}</h6> : <div></div>) : <div></div>}
                                        { property != null ? ((property.user_middle_name == null || property.user_middle_name == "null") ? <h6 className="paragraph">{property.user_first_name + " " + property.user_last_name}</h6> : <div></div>) : <div></div>}
                                    </center>
                                    {/*<div className="d-flex subHeading" style={{ marginTop:"50px" }}><h6 style={{ color:"orange" }}>Request</h6>&nbsp;<h6>Enquiry</h6></div>
                                    <input className="paragraph" type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} value={ (cookies.middle_name != 'null' ? cookies.first_name + " " + cookies.middle_name + " " + cookies.last_name : cookies.first_name + " " + cookies.last_name) } disabled placeholder="Name"></input>
                                    <input className="paragraph" type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} value={ cookies.mobile } disabled placeholder="Mobile No."></input>
                                    <input className="paragraph" type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} value={ cookies.email } disabled placeholder="Email"></input>
                                    <textarea className="paragraph" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} value={message} onChange={(e) => setMessage(e.target.value)}  placeholder="Type what you want" rows="4" cols="40"></textarea>
                                    <br></br>*/}
                                    <center>
                                        <button className="paragraph" style={{ color:"white",backgroundColor:"#FF4500",border:"1px solid #FF4500",borderRadius:"25px",padding:"10px",paddingLeft:"40px",paddingRight:"40px",margin:"5px" }}>Download the app to contact the owner</button>
                                    </center>
                                    <h6 className="paragraph" style={{ color:"orange", marginTop:"50px" }}>Details</h6>
                                    <table className="paragraph" cellPadding="10" style={{ width:"100%" }}>
                                        <tr>
                                            <td><b>Price:</b></td>
                                            <td>{ property != null ? property.budget : 0 }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Contract Type:</b></td>
                                            <td>{ property != null ? (property.buy == 0 ? "Sale" : "Rent") : "Not Applicable" }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Square Ft:</b></td>
                                            <td>{ property != null ? property.project_area : 0 }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Bedrooms:</b></td>
                                            <td>{ property != null ? property.bedrooms : 0 }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Bathrooms:</b></td>
                                            <td>{ property != null ? property.bathrooms : 0 }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Beds:</b></td>
                                            <td>{ property != null ? property.beds : 0 }</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       	   <ToastContainer />
	</div>
    );
}

export default PropertyDetails;
