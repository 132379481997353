import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import Background from './Background';
import BootstrapSidebar from './BootstrapSidebar';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import { getDataForPropertiesByMonth } from './Utils';
import { useTranslation } from 'react-i18next';

const Main = () => {
    const [cookies, setCookie] = useCookies(['user']);
    const [chartData,setChartData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pieData,setPieData] = useState([12,4,5,6]);
    const [graphData,setGraphData] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [commercialGraphData,setCommercialGraphData] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [homeGraphData,setHomeGraphData] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [apartmentGraphData,setApartmentGraphData] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [coWorkingSpaceGraphData,setCoWorkingSpaceGraphData] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [warehouseGraphData,setWarehouseGraphData] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [landGraphData,setLandGraphData] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [selectedYear, setSelectedYear] = useState(2022);
    const [currentYear,setCurrentYear] = useState(2022);
    ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    const years = Array.from({ length: currentYear - 1900 + 1 }, (_, index) => currentYear - index);
    const { t, i18n } = useTranslation();

    const handleYearChange = (e=null) => {
        
        var data = new FormData();
        
        data.append("id",11);
        if(e != null) {
            setSelectedYear(parseInt(e.target.value));
            data.append("year",parseInt(e.target.value));
        } else {
            data.append("year",2022);
        }
        setIsLoading(true);
        fetch('https://api.connecker.com/api/get_dashboard_data', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(data => {
            setIsLoading(false);
            if(data.response_code == 200) {
                setChartData(data.data);
                var dataArray = [];
                dataArray.push(data.data.number_of_commercial);
                dataArray.push(data.data.number_of_home);
                dataArray.push(data.data.number_of_apartment);
                dataArray.push(data.data.number_of_coworking_space);
                dataArray.push(data.data.number_of_warehouse);
                dataArray.push(data.data.number_of_land);
                setPieData(dataArray);

                var graphDataArray = [0,0,0,0,0,0,0,0,0,0,0,0];
                var commercialGraphDataArray = [0,0,0,0,0,0,0,0,0,0,0,0];
                var homeGraphDataArray = [0,0,0,0,0,0,0,0,0,0,0,0];
                var apartmentGraphDataArray = [0,0,0,0,0,0,0,0,0,0,0,0];
                var coWorkingSpaceGraphDataArray = [0,0,0,0,0,0,0,0,0,0,0,0];
                var warehouseGraphDataArray = [0,0,0,0,0,0,0,0,0,0,0,0];
                var landGraphDataArray = [0,0,0,0,0,0,0,0,0,0,0,0];
                
                graphDataArray = getDataForPropertiesByMonth(data.data.number_of_properties_by_month);
                commercialGraphDataArray = getDataForPropertiesByMonth(data.data.number_of_commercial_by_month);
                homeGraphDataArray = getDataForPropertiesByMonth(data.data.number_of_home_by_month);
                apartmentGraphDataArray = getDataForPropertiesByMonth(data.data.number_of_apartment_by_month);
                coWorkingSpaceGraphDataArray = getDataForPropertiesByMonth(data.data.number_of_coworking_space_by_month);
                warehouseGraphDataArray = getDataForPropertiesByMonth(data.data.number_of_warehouse_by_month);
                landGraphDataArray = getDataForPropertiesByMonth(data.data.number_of_land_by_month);

                setGraphData(graphDataArray);
                setCommercialGraphData(commercialGraphDataArray);
                setHomeGraphData(homeGraphDataArray);
                setApartmentGraphData(apartmentGraphDataArray);
                setCoWorkingSpaceGraphData(coWorkingSpaceGraphDataArray);
                setWarehouseGraphData(warehouseGraphDataArray);
                setLandGraphData(landGraphDataArray);

            } else if(data.response_code == 404) {
                setChartData(null);
            }
         });
    };

    useEffect(() => {
        const abortController = new AbortController();
        const tempYear = new Date().getFullYear();
        setCurrentYear(tempYear);
        setSelectedYear(currentYear);
        handleYearChange(null);
       },[]);

    const options = {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: t("new_properties_posted_by_month"),
          },
        },
    };
      
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August','September','October','November','December'];
      
    const data2 = {
        labels,
        datasets: [
          {
            label: t("number_of_properties"),
            data: graphData,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 0, 0, 0.5)',
          },
          {
            label: t("number_of_commercial"),
            data: commercialGraphData,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
          },
          {
            label: t("number_of_home"),
            data: homeGraphData,
            borderColor: 'rgba(54, 162, 235, 1)',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
          },
          {
            label: t("number_of_apartment"),
            data: apartmentGraphData,
            borderColor: 'rgba(255, 206, 86, 1)',
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
          },
          {
            label: t("number_of_coworking_space"),
            data: coWorkingSpaceGraphData,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
          {
            label: t("number_of_warehouse"),
            data: warehouseGraphData,
            borderColor: 'rgba(153, 102, 255, 1)',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
          },
          {
            label: t("number_of_land"),
            data: landGraphData,
            borderColor: 'rgba(255, 159, 64, 1)',
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
          },
        ],
      };

    var data1 = {
    labels: [t("commercial"), t("home"), t("apartment"), t("coworking_space"), t("warehouse"), t("land")],
    datasets: [
        {
        label: t("types_of_properties"),
        data: pieData,
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
        },
    ],
    };

    return (
        <div className="content">
          <div className="row justify-content-center">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                    <Background></Background>
                    <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>{t("dashboard")}</h4>
                    <div class="row d-flex jusitfy-content-center" style={{ marginTop:"150px" }}>
                        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                            <div class="card" style={{width:"18rem", marginTop:"1rem"}}>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5 class="card-title subHeading">{t("properties")}</h5>
                                        </div>
                                        <div className="col-sm-6">
                                        <h5 class="card-title paragraph">{ chartData != null ? chartData.number_of_properties : 0 }</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h6 class="card-title paragraph" style={{ border:"1px solid orange", borderRadius:"16px", padding:"5px", color:"orange" }}>{t("sale")} { chartData != null ? chartData.number_of_properties_for_sale : 0 }</h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <h6 class="card-title paragraph" style={{ border:"1px solid green", borderRadius:"16px", padding:"5px", color:"green" }}>{t("rent")} { chartData != null ? chartData.number_of_properties_for_rent : 0 }</h6>
                                        </div>
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                </div>
                                <img src="/images/graph_orange.png" className="img-fluid"></img>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                            <div class="card" style={{width:"18rem" , marginTop:"1rem"}}>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5 class="card-title subHeading">{t("users")}</h5>
                                        </div>
                                        <div className="col-sm-6">
                                        <h5 class="card-title paragraph">{ chartData != null ? chartData.number_of_users : 0 }</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {/*<h6 class="card-title" style={{ border:"1px solid green", borderRadius:"16px", padding:"5px", color:"green" }}>Active 13</h6>*/}
                                        </div>
                                        <div className="col-sm-6">
                                            {/*<h6 class="card-title" style={{ border:"1px solid orange", borderRadius:"16px", padding:"5px", color:"orange" }}>Inactive 7</h6>*/}
                                        </div>
                                    </div>
                                </div>
                                <img src="/images/graph_blue.png" className="img-fluid"></img>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                            <div class="card" style={{width:"18rem" , marginTop:"1rem"}}>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5 class="card-title subHeading">{t("messages")}</h5>
                                        </div>
                                        <div className="col-sm-6">
                                        <h5 class="card-title paragraph">{ chartData != null ? chartData.messages : 0 }</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {/*<h6 class="card-title" style={{ border:"1px solid green", borderRadius:"16px", padding:"5px", color:"green" }}>Active 13</h6>*/}
                                        </div>
                                        <div className="col-sm-6">
                                            {/*<h6 class="card-title" style={{ border:"1px solid orange", borderRadius:"16px", padding:"5px", color:"orange" }}>Inactive 7</h6>*/}
                                        </div>
                                    </div>
                                </div>
                                <img src="/images/graph_orange.png" className="img-fluid"></img>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                            <div class="card" style={{width:"18rem" , marginTop:"1rem"}}>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5 class="card-title subHeading">{t("careers")}</h5>
                                        </div>
                                        <div className="col-sm-6">
                                        <h5 class="card-title paragraph">{ chartData != null ? chartData.careers : 0 }</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {/*<h6 class="card-title" style={{ border:"1px solid green", borderRadius:"16px", padding:"5px", color:"green" }}>USD $40M</h6>*/}
                                        </div>
                                        <div className="col-sm-6">
                                            {/*<h6 class="card-title" style={{ border:"1px solid orange", borderRadius:"16px", padding:"5px", color:"orange" }}>RS. 50K</h6>*/}
                                        </div>
                                    </div>
                                </div>
                                <img src="/images/graph_green.png" className="img-fluid"></img>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-3 mt-3 rounded">
                        
                        <div className="row" style={{ marginTop:"20px" }}>
                            <div className="col-lg-3 col-xxl-3">
                                <div className="col-lg-6 col-xxl-6 mt-lg-5 mt-xxl-5">
                                    <div className="d-flex">
                                        <h6 className="heading" style={{ color:"orange" }}>{t("total")}</h6>
                                        &nbsp;
                                        <h6 className="heading" style={{ color:"black" }}>{t("properties")}</h6>
                                    </div>
                                </div>
                                <Doughnut data={data1}  width={"30%"}  />
                            </div>
                            <div className="col-lg-3 col-xxl-3"></div>
                            <div className="col-lg-6 col-xxl-6">
                                <div className="col-lg-6 col-xxl-6 mt-md-5 mt-sm-5 mt-5">
                                    <div className="d-flex">
                                        <h6 className="heading" style={{ color:"orange" }}>| {t("graph")}</h6>
                                        &nbsp;
                                        <h6 className="heading" style={{ color:"black" }}>{t("by_year")}</h6>
                                    </div>
                                </div>
                                <label htmlFor="year">{t("select_a_year")}:</label>
                                <select id="year" value={selectedYear} onChange={handleYearChange}>
                                    {years.map(year => (
                                    <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>
                                <Bar options={options} data={data2} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;