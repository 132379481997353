import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaMapMarkerAlt, FaMicrosoft, FaBed, FaCarAlt, FaHome, FaCheckCircle, FaCloudUploadAlt } from "react-icons/fa";
import { useParams, useHistory } from 'react-router-dom';
import Background from './Background';
import { useState, useEffect, useCallback } from 'react';
import './TableStyle.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import {useDropzone} from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import React from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function AddCareer() {

    const inputRef = React.createRef();
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [experience,setExperience] = useState("");
    const [type,setType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation();

    const addCareerAPI = () => {
        var data = new FormData();
        if(title == "") {
            toast(t("enter_title"));
        } else if(description == "") {
            toast(t("enter_description"));
        } else if(experience == "") {
            toast(t("enter_experience"));
        } else if(type == "") {
            toast(t("enter_type"));
        } else {
            data.append("title",title);
            data.append("description",description);
            data.append("experience",experience);
            data.append("type",type);
            setIsLoading(true);
            fetch('https://api.connecker.com/api/add_career', {
                method: 'POST',
                body: data
            }).then(res => {
                setIsLoading(false);
                if(!res.ok) {
                    throw Error('Could not fetch');
                }
                return res.json();
            }).then(async(data) => {
                setIsLoading(false);
                if(data.response_code == 200) {
                    toast(data.message);
                } else if(data.response_code == 404) {
                    toast(data.message);
                } else {
                    toast(data.message);
                }
            });
        }
    }

    return(
        <div className="content">
            <div className="row d-flex justify-content-center">
                <div className="col-2 col-sm-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10">
                    <Background></Background>
                    <h4 style={{ color:"white", marginTop:"50px" }}>{t("add_career")}</h4>
                    <div class="card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className="d-flex">
                                <h6 style={{ color:"orange" }}>{t("basic")}</h6>
                                &nbsp;
                                <h6>{t("information")}</h6>
                            </div>
                            <br></br>
                            <input type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setTitle(e.target.value)} placeholder={t("title")}></input>
                            <input type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setExperience(e.target.value)} placeholder={t("experience")}></input>
                            <input type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setType(e.target.value)} placeholder={t("type")}></input>
                            <br></br>
                            <textarea rows="10" cols="80" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setDescription(e.target.value)} placeholder={t("description")}/>
                            
                            <br></br>
                            <button onClick={() => addCareerAPI()} style={{ color:"white",backgroundColor:"#FF4500",border:"1px solid #FF4500",borderRadius:"25px",padding:"10px",paddingLeft:"40px",paddingRight:"40px",margin:"5px" }}>{t("submit")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddCareer;