import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useFetch from './useFetch';

function BlogDetails() {
    const { id } = useParams();
    const {data: blog, isPending, error} = useFetch('http://localhost:3303/blogs/' + id);
    const handleDelete = () => {
        fetch('http://localhost:3303/blogs/' + id, {
            method: 'DELETE'
        })
        .then(()=>{
            history.push('/');
        });
    }
    const history = useHistory();

    return (
        <div>
            { isPending && <div>Loading...</div> }
            { error && <div>{ error }</div> }
            { blog && (
                <article>
                    <h2>{ blog.title }</h2>
                    <p>Written by: { blog.author }</p>
                    <button onClick={handleDelete}>Delete</button>
                </article>
            ) }
        </div>
    );
}

export default BlogDetails;