import { useState, useEffect } from 'react';

const useFetch = (url) => {

    const [data,setData] = useState(null);
    const [isPending,setIsPending] = useState(true);
    const [error,setError] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();


        fetch(url,{ signal: abortController.signal })
        .then(res => {
           if(!res.ok) {
               throw Error('Could not fetch');
           }
           return res.json();
        })
        .then(data => {
           setData(data);
           setIsPending(false);
           setError(null);
        })
        .catch((err) => {
            if(err.name === "AbortError") {
                console.log("Fetch Aborted");
            } else {
                setIsPending(false);
                setError(err);
            }
        }) 
        
        return () => abortController.abort();
       },[url]); //adding [] runs the useEffect not on every render but just on the first render or
       //when the entered value in the [] changes

       return { data, isPending, error }
}

export default useFetch;