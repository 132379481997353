import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaSearch } from "react-icons/fa";
import Background from './Background';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function ChatList() {

    const [cookies, setCookie] = useCookies(['user']);
    const [chatList,setChatList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getChatList = () => {
        var data = new FormData();
        
        data.append("user_id",11);
        setIsLoading(true);
        fetch('https://api.connecker.com/api/get_chats', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(data => {
            setIsLoading(false);
            if(data.response_code == 200) {
               setChatList(data.data);
            } else if(data.response_code == 404) {
                setChatList([]);
            }
         });
    }

    useEffect(() => {
        const abortController = new AbortController();
        getChatList();
       },[]);

    return(
        <div className="content">
            <div className="row justify-content-start">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                    <Background></Background>
                    <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>Chat List</h4>
                    <div className="card" style={{borderColor:"white"}}>
                        <div className="table-responsive">
                            <table style={{width:"100%"}} className="table table-borderless">
                                <thead>
                                    <tr className="paragraph" style={{ color:"grey" }}>
                                        <td>
                                            <b>#</b>
                                        </td>
                                        <td>
                                            <b>Name</b>
                                        </td>
                                        <td>
                                            <b>Email</b>
                                        </td>
                                        <td>
                                            <b>Message</b>
                                        </td>
                                        <td>
                                            <b>Mobile</b>
                                        </td>
                                        <td>
                                            <b>Actions</b>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        chatList.map((chat, i) => (
                                                <tr className="paragraph">
                                                    <td>
                                                        {i+1}
                                                    </td>
                                                    <td>
                                                        {(chat.sender_id != 11) ? chat.sender.middle_name != null ? (chat.sender.first_name + " " + chat.sender.middle_name + " " + chat.sender.last_name) : "" : ""}
                                                        {(chat.sender_id != 11) ? chat.sender.middle_name == null ? (chat.sender.first_name + " " + chat.sender.last_name) : "" : ""}
                                                        {(chat.receiver_id != 11) ? chat.receiver.middle_name != null ? (chat.receiver.first_name + " " + chat.receiver.middle_name + " " + chat.receiver.last_name) : "" : ""}
                                                        {(chat.receiver_id != 11) ? chat.receiver.middle_name == null ? (chat.receiver.first_name + " " + chat.receiver.last_name) : "" : ""}
                                                    </td>
                                                    <td>
                                                        {(chat.sender_id != 11) ? chat.sender.email : ""}
                                                        {(chat.receiver_id != 11) ? chat.receiver.email : ""}
                                                    </td>
                                                    <td>
                                                        {(chat.sender_id != 11) ? "Correspondent: "+chat.message : "You: "+chat.message}
                                                    </td>
                                                    <td>
                                                        {(chat.receiver_id != 11) ? chat.receiver.mobile : ""}
                                                    </td>
                                                    <td>
                                                        <Link to={`/chat_details/${chat.sender_id}/${chat.receiver_id}`}>View</Link>
                                                    </td>
                                                </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatList;